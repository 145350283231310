@if (accordionWizardConfig) {
  <dpa-accordion [class.single-accordion-step]="accordionSteps.length === 1">
    @for (accordionStep of accordionSteps; track stepIndex; let stepIndex = $index) {
      <div
        dpaAccordionRow
        [title]="accordionStep.title"
        [description]="accordionStep.description"
        [expanded]="accordionWizardConfig.openStepIndices.has(stepIndex)"
        [disabled]="!accordionWizardConfig.enabledStepIndices.has(stepIndex)"
        (click)="toggleStepByIndex(stepIndex)"
        (keyup.enter)="toggleStepByIndex(stepIndex)"
      >
        <ng-template dpaAccordionRowContent>
          @if (preMountContent || accordionWizardConfig.openStepIndices.has(stepIndex)) {
            <ng-container [ngTemplateOutlet]="accordionStep.contentTemplate"></ng-container>
          }
        </ng-template>
      </div>
    }
  </dpa-accordion>
}
