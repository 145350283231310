<div>
  @if (displaySearch) {
    <dpa-local-search
      [placeHolderText]="'COMMON_ACTIONS.SEARCH' | translate"
      (onSearch)="onSearchAvailableColumns($event)"
      iconClass="search"
    ></dpa-local-search>
  }
  @if (treeObj?.length > 0) {
    <div>
      <clr-tree>
        <dpa-tree-node
          [treeViewItems]="treeObj"
          [selectOnlyLeafNode]="selectOnlyLeafNode"
          [treeType]="treeType"
          (selectedChange)="checkForChanges($event)"
          (expandedChange)="checkForExpanded($event)"
        ></dpa-tree-node>
      </clr-tree>
    </div>
  } @else {
    <div
      class="no-items"
      aria-live="polite"
    >
      {{ 'COMMON_MESSAGES.NO_ITEMS_FOUND' | translate }}
    </div>
  }
</div>
