<dpa-tooltip [tooltipText]="getTooltipText() | translate">
  <div [dpaInnerHtmlLight]="label"></div>
  @if (detail?.error) {
    <cds-icon
      status="danger"
      size="md"
      shape="exclamation-circle"
    ></cds-icon>
  }
</dpa-tooltip>
@if (detail?.isNormalized) {
  <div class="label">
    {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
  </div>
}
