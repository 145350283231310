<div
  #tableContainer
  (scroll)="onScrollHandler()"
  class="table-container"
>
  <table
    class="table"
    role="presentation"
  >
    <thead>
      <ng-template
        [ngTemplateOutlet]="headerTemplate"
        [ngTemplateOutletContext]="{}"
      ></ng-template>
    </thead>
    <tbody>
      @for (row of rows; track $index) {
        <ng-container
          [ngTemplateOutlet]="rowTemplate"
          [ngTemplateOutletContext]="{ item: row }"
        ></ng-container>
      }
    </tbody>
  </table>
  @if (!loading && !rows.length) {
    <div class="no-results-container">
      <div class="no-results-message">
        {{ 'WORKFLOWS.NO_WORKFLOW_LOGS_FOUND' | translate }}
      </div>
    </div>
  }
  @if (loading) {
    <div class="spinner-container">
      <span class="spinner"></span>
    </div>
  }
</div>

<ng-template #defaultHeaderTemplate>
  <tr></tr>
</ng-template>

<ng-template
  #defaultRowTemplate
  let-item="item"
>
  <tr></tr>
</ng-template>
