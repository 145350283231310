<div class="card">
  <div class="card-block">
    <div class="leaderboards-container">
      @for (leaderboard of leaderboards; track leaderboard) {
        <dpa-leaderboard
          [leaderboard]="leaderboard"
          [showBars]="true"
          class="p-x3"
        ></dpa-leaderboard>
      }
    </div>
  </div>
</div>
