<div class="empty-state-container">
  <div [ngClass]="['bg-image', 'empty-state-img', bgImageClass]"></div>
  <div class="empty-state-info">
    @if (description) {
      <p>
        {{ description }}
      </p>
    }
    <ng-container *ngTemplateOutlet="descriptionTemplate"></ng-container>
    @if (actionName && showAction) {
      <p>
        <button
          (click)="onClick()"
          class="btn btn-primary"
        >
          {{ actionName }}
        </button>
      </p>
    }
  </div>
</div>
