@if (treeType === TREE_TYPE.SELECT) {
  @for (treeViewItem of treeViewItems; track treeViewItem) {
    <div>
      @if (treeViewItem.children) {
        <div>
          @if (selectOnlyLeafNode) {
            <clr-tree-node [(clrSelected)]="treeViewItem.selected">
              <div
                [dpaInnerHtmlLight]="treeViewItem.styledString || treeViewItem.label"
                class="matching-text"
              ></div>
              <ng-template
                [clrIfExpanded]="treeViewItem.isExpanded"
                (clrIfExpandedChange)="setExpanded(treeViewItem, $event)"
              >
                <dpa-tree-node
                  [treeViewItems]="treeViewItem.children"
                  [selectOnlyLeafNode]="selectOnlyLeafNode"
                  (selectedChange)="selectItem($event)"
                  (expandedChange)="changeExpanded($event)"
                ></dpa-tree-node>
              </ng-template>
            </clr-tree-node>
          } @else {
            <clr-tree-node
              [(clrSelected)]="treeViewItem.selected"
              (clrSelectedChange)="selectItem(treeViewItem)"
            >
              <div
                [dpaInnerHtmlLight]="treeViewItem.styledString || treeViewItem.label"
                class="matching-text"
              ></div>
              <ng-template
                [clrIfExpanded]="treeViewItem.isExpanded"
                (clrIfExpandedChange)="setExpanded(treeViewItem, $event)"
              >
                <dpa-tree-node
                  [treeViewItems]="treeViewItem.children"
                  [selectOnlyLeafNode]="selectOnlyLeafNode"
                  (selectedChange)="selectItem($event)"
                  (expandedChange)="changeExpanded($event)"
                ></dpa-tree-node>
              </ng-template>
            </clr-tree-node>
          }
        </div>
      } @else {
        <div>
          <clr-tree-node
            [(clrSelected)]="treeViewItem.selected"
            (clrSelectedChange)="selectItem(treeViewItem)"
          >
            <span
              [dpaInnerHtmlLight]="treeViewItem.styledString || treeViewItem.label"
              class="matching-text"
            ></span>
            @if (treeViewItem.isNormalized) {
              <span class="label">
                {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
              </span>
            }
          </clr-tree-node>
        </div>
      }
    </div>
  }
} @else {
  @for (treeViewItem of treeViewItems; track treeViewItem) {
    <div>
      @if (treeViewItem.children?.length > 0) {
        <div>
          <clr-tree-node>
            <div>
              {{ treeViewItem.label }}
            </div>
            <ng-template
              [clrIfExpanded]="treeViewItem.isExpanded"
              (clrIfExpandedChange)="setExpanded(treeViewItem, $event)"
            >
              <dpa-tree-node
                [treeViewItems]="treeViewItem.children"
                [treeType]="treeType"
              ></dpa-tree-node>
            </ng-template>
          </clr-tree-node>
        </div>
      } @else {
        <clr-tree-node>
          {{ treeViewItem.label }}
        </clr-tree-node>
      }
    </div>
  }
}
