<div class="container ph-4">
  @if (isSearchable) {
    <dpa-local-search
      [placeHolderText]="searchPlaceholder"
      (onSearch)="searchQueryChange.emit($event)"
      iconClass="search"
      class="mt-x3"
    ></dpa-local-search>
  }
  @if (isFilterable && filterTags?.length) {
    <dpa-filter-tags
      [filterTags]="filterTags"
      (onFilterChange)="filterChange.emit($event)"
      class="mv-x1"
    ></dpa-filter-tags>
  }
</div>
<div
  [class.top-border]="showListDivider"
  class="scroll-container"
>
  @if (items?.length) {
    <div class="scroll-content-wrapper">
      <div class="scroll-content ph-x4">
        @for (item of items; track item; let last = $last) {
          <div>
            <ng-container
              [ngTemplateOutlet]="itemTemplate"
              [ngTemplateOutletContext]="{ item, last }"
            ></ng-container>
          </div>
        }
      </div>
    </div>
  } @else {
    <dpa-no-search-results
      [message]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
      class="no-search-results mv-6"
    ></dpa-no-search-results>
  }
</div>
