@if (dataGridColumns?.length) {
  <dpa-data-grid
    [loading]="loading"
    [response]="localPagedResponse"
    [sortOns]="defaultedDataGridSettings.sortOns"
    [selectable]="selectable"
    [pagination]="enablePagination"
    [pageSize]="defaultedDataGridSettings.pagedRequest.size"
    [noResultsText]="noResultsText || ('COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate)"
    [noResultsSubtext]="noResultsSubtext"
    [expandedRowTemplate]="expandedRowTemplate"
    [showDetailView]="showDetailView"
    [detailHeaderTemplate]="detailHeaderTemplate"
    [detailBodyTemplate]="detailBodyTemplate"
    [columnWidthByKey]="columnWidthByKey"
    (selectionChange)="onSelectionChange($event)"
    (sortChange)="onSortOnsChange($event)"
    (pageChange)="onPageChange($event)"
    (refreshClick)="onRefreshClick()"
  >
    @for (dataGridColumn of dataGridColumns; track dataGridColumn) {
      <div
        dpaDataGridColumn
        [field]="dataGridColumn.value"
        [sortBy]="dataGridColumn.value"
        [title]="dataGridColumn.label | translate"
        [sortable]="true"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          @if (cellTemplatesByColumnValue[dataGridColumn.value]) {
            <ng-container
              [ngTemplateOutlet]="cellTemplatesByColumnValue[dataGridColumn.value]"
              [ngTemplateOutletContext]="{ dataItem: dataItem, dataGridColumn: dataGridColumn, dataValue: dataItem[dataGridColumn.value] }"
            ></ng-container>
          } @else {
            <dpa-decorated-value
              [value]="dataItem[dataGridColumn.value]"
              [valueDecorator]="dataGridColumn.valueDecorator"
            ></dpa-decorated-value>
          }
        </ng-template>
      </div>
    }
  </dpa-data-grid>
}
