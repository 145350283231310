<div
  [class.selected]="template?.templateId === selectedTempalteId"
  class="card"
>
  <div
    [class.full-row-content]="hideFooter"
    class="card-block content pb-x1"
  >
    <div class="template-card-header mb-x1">
      <h4 class="mt-0">
        <dpa-tooltip
          [attr.aria-label]="template.name"
          [tooltipText]="template.name"
          [focusEnabled]="true"
          role="tooltip"
        >
          <div
            [dpaInnerHtmlLight]="styledString || template.label || template.name"
            class="matching-text text-ellipsis"
          ></div>
        </dpa-tooltip>
      </h4>
      @if (bookmarksEnabled) {
        <div class="bookmark-container">
          <button
            [attr.aria-label]="(template.bookmarked ? 'BOOKMARKS.REMOVE_FROM_BOOKMARKS' : 'BOOKMARKS.ADD_TO_BOOKMARKS') | translate"
            [disabled]="bookmarkInProgress"
            (click)="onToggleBookmark(template)"
            class="btn btn-link bookmark-btn clickable"
            type="button"
          >
            <cds-icon
              [attr.solid]="template.bookmarked"
              size="18"
              shape="bookmark"
              class="is-solid"
            ></cds-icon>
          </button>
        </div>
      }
    </div>
    <dpa-text-fade
      [maxLines]="2"
      [text]="template.description"
    ></dpa-text-fade>
    <div class="tags">
      <ng-template
        [ngTemplateOutlet]="tagsTemplate"
        [ngTemplateOutletContext]="{ tags: tags }"
      ></ng-template>
      @if (template.isPredefinedReportTemplate) {
        <dpa-tooltip [tooltipText]="'WIDGET_TEMPLATE.PREDEFINED_REPORT_INFO' | translate">
          <div class="dpa-icon predefined-report"></div>
        </dpa-tooltip>
      }
    </div>
  </div>
  @if (customCardBlock) {
    <ng-template
      [ngTemplateOutlet]="customCardBlock"
      [ngTemplateOutletContext]="{ template: template }"
    ></ng-template>
  }
  @if (!hideFooter) {
    <div class="card-footer">
      <div class="clr-row clr-flex-items-sm-middle">
        <div class="clr-col-sm">
          @for (action of actions; track action?.label) {
            @if (!action.isHidden?.(template)) {
              <button
                [class]="action.className"
                [disabled]="action.isDisabled?.(template)"
                (click)="action.onClick(template)"
                (keyup.enter)="action.onClick(template)"
                class="btn btn-sm btn-link mr-2"
              >
                {{ action.label }}
              </button>
            }
          }
        </div>
        @if (dropdownActions?.length && template.isCustomTemplate) {
          <clr-dropdown>
            <button
              clrDropdownTrigger
              [attr.aria-label]="'COMMON_ACTIONS.CARD_OPTIONS' | translate"
            >
              <cds-icon
                shape="ellipsis-horizontal"
                size="24"
              ></cds-icon>
            </button>
            <clr-dropdown-menu
              *clrIfOpen
              [clrPosition]="CLARITY_TOOLTIP_POSITION.TOP_RIGHT"
            >
              @for (action of dropdownActions; track action?.label) {
                @if (!action.isHidden?.(template)) {
                  <button
                    clrDropdownItem
                    [class]="action.className"
                    (click)="action.onClick(template)"
                    type="button"
                  >
                    {{ action.label }}
                  </button>
                }
              }
            </clr-dropdown-menu>
          </clr-dropdown>
        }
      </div>
    </div>
  }
</div>
