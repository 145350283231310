@if (filterList.length && !isMinimized) {
  <ng-container class="quick-filter-container">
    <div class="tag-box">
      @for (key of filterList; track key) {
        <span
          dpaTabEvent
          [class.label-info]="filtersStatus[key]"
          (click)="toggleFilter(key)"
          (keyup.enter)="toggleFilter(key)"
          class="label label-light-blue quick-filters mb-x1 ml-x1"
          role="button"
        >
          {{ tagLabels[key] | translate }}
        </span>
      }
    </div>
  </ng-container>
} @else {
  <clr-dropdown>
    <button
      clrDropdownTrigger
      [attr.aria-label]="'COMMON_MESSAGES.QUICK_FILTERS' | translate"
      class="btn btn-link pl-0"
      type="button"
    >
      <cds-icon
        shape="filter"
        size="18"
        class="filter-icon"
      ></cds-icon>
      {{ 'COMMON_MESSAGES.QUICK_FILTERS' | translate }}
      @if (selectedFilterCount > 0) {
        <span class="badge badge-info widget-badge-info">
          {{ selectedFilterCount }}
        </span>
      }
      <cds-icon
        [class.down]="!isDropdownOpen"
        shape="angle"
        class="angle-icon"
      ></cds-icon>
    </button>
    <ng-template [(clrIfOpen)]="isDropdownOpen"></ng-template>
    <clr-dropdown-menu
      *clrIfOpen
      [clrPosition]="CLARITY_TOOLTIP_POSITION.BOTTOM_RIGHT"
    >
      @for (key of filterList; track key) {
        <button
          clrDropdownItem
          [class.label-info]="filtersStatus[key]"
          (click)="toggleFilter(key)"
          class="label label-light-blue quick-filters"
          type="button"
        >
          {{ tagLabels[key] | translate }}
        </button>
      }
    </clr-dropdown-menu>
  </clr-dropdown>
}
