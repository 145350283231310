@if (showSearch || groupCollections?.length > 1) {
  <div class="control-container ph-x3 pb-x3">
    @if (showSearch) {
      <div class="ph-x1 pt-x2">
        @if (searchTitle) {
          <h6 class="mt-1">
            {{ searchTitle }}
          </h6>
        }
        <div class="clr-row mt-x1">
          <dpa-local-search
            [placeHolderText]="searchPlaceholder ?? ''"
            (onSearch)="onSearchQueryChange($event)"
            iconClass="search"
            class="clr-col"
          ></dpa-local-search>
        </div>
      </div>
    }
    @if (groupCollections?.length > 1) {
      <div class="group-by mt-x3 ph-x1">
        <h6 class="small-header mr-1">{{ 'COMMON_ACTIONS.GROUP_BY' | translate | titlecase }}:</h6>
        <clr-dropdown>
          <button
            clrDropdownTrigger
            type="button"
            class="btn-sm btn-link"
          >
            <span class="p3 mt-0">
              {{ selectedGroupCollection?.groupByLabel | translate }}
            </span>
            <cds-icon
              direction="down"
              shape="angle"
            ></cds-icon>
          </button>
          <clr-dropdown-menu
            *clrIfOpen
            [clrPosition]="dropdownPosition"
          >
            @for (groupCollection of groupCollections; track groupCollection) {
              <button
                clrDropdownItem
                (click)="onGroupCollectionChange(groupCollection)"
              >
                {{ groupCollection.groupByLabel | translate }}
              </button>
            }
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>
    }
  </div>
}
@if (!isResultsVisible) {
  <div
    [class.top-border]="showListDivider"
    class="list-wrapper"
  >
    <div
      [class.divider]="!isSinglePane"
      class="list-container"
    >
      <div
        [class.details-list-visible]="selectedGroup && isSinglePane"
        class="list-container-inner"
      >
        <div class="group-list p-x4">
          @if (!isSinglePane && groupsTitleByGroupBy && !hideGroupPaneTitle) {
            <h6 class="small-header mb-2">
              {{ groupsTitleByGroupBy?.[selectedGroupCollection?.groupBy] | translate }}
            </h6>
          }
          @for (group of selectedGroupCollection?.groups; track group; let last = $last) {
            <dpa-list-item
              [isCompact]="true"
              [isBoxed]="applyBoxStyling"
              [isHoverable]="true"
              [isSelected]="!isSinglePane && selectedGroup?.name === group.name"
              [label]="group.label"
              [hasArrow]="showArrowWithCount"
              [arrowText]="showArrowWithCount ? group.items.length + '' : undefined"
              [iconTemplate]="iconTemplate"
              (itemClick)="setSelectedGroup(group)"
            ></dpa-list-item>
            <ng-template #iconTemplate>
              <ng-container
                [ngTemplateOutlet]="groupIconTemplate"
                [ngTemplateOutletContext]="{
                  group,
                  groupCollection: selectedGroupCollection
                }"
              ></ng-container>
            </ng-template>
          }
        </div>
        @if (isSinglePane) {
          <div class="details-list">
            <ng-container [ngTemplateOutlet]="detailsView" />
          </div>
        }
      </div>
    </div>
    @if (!isSinglePane) {
      <div class="details-list">
        <ng-container [ngTemplateOutlet]="detailsView" />
      </div>
    }
  </div>
} @else {
  <ng-container [ngTemplateOutlet]="detailsView"></ng-container>
}

<ng-template #detailsView>
  <div
    [class.top-border]="showListDivider && isResultsVisible"
    class="scroll-container"
  >
    @if (isResultsVisible) {
      <dpa-no-search-results
        [message]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
        class="no-search-results mv-6"
      ></dpa-no-search-results>
    } @else {
      <div class="scroll-content p-x4">
        @if (isSinglePane) {
          <div class="canvas-details-header pv-x2">
            <button
              (click)="selectedGroup = undefined"
              class="btn btn-sm btn-icon btn-link"
            >
              <cds-icon
                shape="arrow"
                direction="left"
                size="24"
              ></cds-icon>
            </button>
            <ng-container
              [ngTemplateOutlet]="groupIconTemplate"
              [ngTemplateOutletContext]="{
                group: selectedGroup,
                groupCollection: selectedGroupCollection
              }"
            ></ng-container>
            <h6 class="mt-0">
              {{ selectedGroup?.label }}
            </h6>
          </div>
        }
        @if (!isSinglePane && itemsTitle) {
          <h6 class="small-header pt-2">
            {{ itemsTitle }}
          </h6>
        }
        @for (item of searchResults ?? selectedGroup?.items; track item; let last = $last) {
          <div>
            <dpa-list-item
              [isCompact]="hasCompactItems"
              [isHoverable]="hasCompactItems"
              [label]="(isResultsVisible ? item?.groupLabel + ' > ' : '') + item?.label"
              [description]="item?.description || 'Description goes here'"
              [actionsTemplate]="itemActionsTemplate"
              [item]="item"
              (click)="itemSelect.emit(item)"
              (keyup.enter)="itemSelect.emit(item)"
            ></dpa-list-item>
          </div>
        }
      </div>
    }
  </div>
</ng-template>
