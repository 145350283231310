@if (showHeading) {
  <h4 class="mt-0">
    {{ 'COMMON_MESSAGES.CODE_PREVIEW' | translate }}
  </h4>
}
<!-- prettier-ignore -->
<!-- linthtml-configure indent-width="false" -->
<!-- //NOSONAR -->
@if (linesOfCode?.length > 0) {
  <pre class="codeblock mt-x2">@for (line of linesOfCode; track $index) {
    <span
      [innerHTML]="line | safe: ContentType.HTML"
      class="line">
    </span>
  }</pre>
}
@if (isDownloadEnabled) {
  <div class="mb-3">
    <button
      (click)="onDownloadClick()"
      class="btn btn-sm btn-link pl-0"
    >
      <cds-icon
        shape="download"
        size="12"
      ></cds-icon>
      {{ 'COMMON_ACTIONS.DOWNLOAD' | translate }}
    </button>
    <button
      [disabled]="!isDeployEnabled"
      (click)="onDeployClick()"
      type="button"
      class="btn btn-primary"
    >
      {{ 'COMMON_ACTIONS.DEPLOY' | translate }}
    </button>
  </div>
}
