@if (!tooltipText) {
  <dpa-tooltip
    [attr.aria-label]="labelText + ', ' + tooltipText"
    [tooltipText]="tooltipText"
    role="tooltip"
  >
    <div class="label info-badge">
      <cds-icon
        [attr.shape]="iconShape"
        [attr.size]="iconSize"
      ></cds-icon>
      {{ labelText }}
    </div>
  </dpa-tooltip>
} @else {
  <div class="label">
    {{ labelText }}
  </div>
}
