<clr-dropdown>
  <button
    clrDropdownTrigger
    [attr.aria-label]="'COMMON_ACTIONS.CARD_OPTIONS' | translate"
    class="btn-sm btn-link connection-ellipsis ph-0"
  >
    <cds-icon
      shape="ellipsis-horizontal"
      size="24"
    ></cds-icon>
  </button>
  <clr-dropdown-menu
    *clrIfOpen
    [clrPosition]="CLARITY_TOOLTIP_POSITION.TOP_RIGHT"
  >
    @for (option of dropdownOptions; track option) {
      @if (!option.isHidden) {
        <button
          clrDropdownItem
          [disabled]="option.isDisabled"
          [ngClass]="option.textClass"
          (click)="option.action()"
        >
          {{ option.labelKey | translate }}
          @if (option.infoTooltipTextKey) {
            <dpa-info-tooltip
              [tooltipAriaLabel]="option.infoTooltipTextKey | translate"
              [tooltipText]="option.infoTooltipTextKey | translate"
            ></dpa-info-tooltip>
          }
        </button>
      }
    }
  </clr-dropdown-menu>
</clr-dropdown>
