@if (!showEditDeleteConfirmModal && deletingComment?.id === commentId) {
  <clr-alert
    clrAlertType="warning"
    (clrAlertClosedChange)="onCancelDelete()"
    class="mb-x2"
  >
    <clr-alert-item>
      <span class="alert-text">{{ 'COMMON_MESSAGES.DELETE_COMMENT_CONFIRMATION' | translate }}</span>
      <div class="alert-actions">
        <div class="alert-action dropdown">
          <a
            (click)="onConfirmDelete()"
            class="alert-action clickable text-danger"
          >
            {{ 'COMMON_MESSAGES.DELETE' | translate }}
          </a>
          <a
            (click)="onCancelDelete()"
            class="alert-action clickable"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </a>
        </div>
      </div>
    </clr-alert-item>
  </clr-alert>
}
<div class="comment">
  <div class="avatar">
    <!-- linthtml-configure img-req-src="false" -->
    @if (avatarUrl) {
      <img
        [src]="avatarUrl"
        alt="{{ 'DEEM_COMMENTS.PROFILE_IMAGE' | translate }}"
        class="profile-icon"
      />
    } @else {
      <cds-icon
        class="profile-icon"
        shape="dpa-blank-avatar"
      ></cds-icon>
    }
    <!-- linthtml-configure img-req-src="true" -->
  </div>
  <div class="content">
    <div class="content-header">
      <div class="author">
        <a class="btn btn-link author-link">
          {{ userDetails | userInfo }}
        </a>
      </div>
      @if (isActionButtonsVisible()) {
        <div class="actions">
          <cds-icon
            (click)="onEditClick()"
            (keydown.enter)="onEditClick()"
            shape="note"
          ></cds-icon>
          <cds-icon
            (click)="onDeleteClick()"
            (keydown.click)="onDeleteClick()"
            shape="trash"
          ></cds-icon>
        </div>
      }
    </div>
    @if (editingComment?.id !== commentId) {
      <div class="comment-content">
        <p [outerHTML]="text"></p>
        <div class="metadata">
          <dpa-from-now [timestamp]="lastUpdatedAt"></dpa-from-now>
        </div>
      </div>
    } @else if (isEditingComment) {
      <div>
        <form [formGroup]="editCommentForm">
          <quill-editor
            dpaRichTextEditorConfig
            formControlName="text"
          ></quill-editor>
        </form>
        <div class="edit-button-group">
          <button
            [disabled]="isSavingEditComment"
            (click)="onCancelEdit()"
            class="btn btn-sm btn-link"
            type="button"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            [disabled]="isSavingEditComment"
            (click)="onSaveEdit()"
            class="btn btn-sm btn-primary save-button"
            type="button"
          >
            {{ 'COMMON_ACTIONS.SAVE' | translate }}
          </button>
        </div>
      </div>
    }
  </div>
</div>
