<section class="pb-x3">
  <p
    [innerHTML]="
      'SOLUTIONS.DATA_SOURCE.UEM.DESCRIPTION'
        | translate
          : {
              link: learnMoreLink
            }
        | safe: ContentType.HTML
    "
  ></p>
  <div class="mt-x2">
    @if (datasource) {
      <div class="datasource">
        <span class="title">
          {{ 'SOLUTIONS.DATA_SOURCE.UEM.STATUS' | translate }}
        </span>
        <span>
          {{ (datasource.active ? 'CONNECTIONS.STATUS_AUTHORIZED' : 'CONNECTIONS.STATUS_NOT_AUTHORIZED') | translate }}
          <cds-icon
            [attr.solid]="datasource.active"
            status="success"
            class="mh-x1"
            shape="success-standard"
          ></cds-icon>
        </span>
      </div>
      @if (syncStatus) {
        <div class="datasource">
          <span class="title">{{ etlHost?.name }}:</span>
          @if (etlHost?.value) {
            <span>
              {{ etlHost?.value }}
            </span>
          } @else {
            <span>
              {{ 'COMMON_MESSAGES.UNKNOWN_VALUE' | translate }}
            </span>
          }
        </div>
        <div class="datasource">
          <span class="title">{{ lastCheckin?.name }}:</span>
          @if (lastCheckin?.value) {
            <span>
              {{ lastCheckin?.value | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }}
            </span>
          } @else {
            <span>
              {{ 'COMMON_MESSAGES.UNKNOWN_VALUE' | translate }}
            </span>
          }
        </div>
      }
    } @else {
      <div class="datasource">
        <span class="title">
          {{ 'SOLUTIONS.DATA_SOURCE.UEM.STATUS' | translate }}
        </span>
        <span>
          {{ 'COMMON_MESSAGES.NOT_AVAILABLE' | translate }}
          <cds-icon
            [attr.solid]="false"
            status="danger"
            class="mb-x1"
            shape="success-standard"
          ></cds-icon>
        </span>
      </div>
      <div class="datasource">
        <span class="title">
          {{ 'SOLUTIONS.DATA_SOURCE.UEM.SERVER_NAME' | translate }}
        </span>
        <span>
          {{ 'COMMON_MESSAGES.NOT_AVAILABLE' | translate }}
        </span>
      </div>
      <div class="datasource">
        <span class="title">
          {{ 'SOLUTIONS.DATA_SOURCE.UEM.LAST_CHECK_IN_TIME' | translate }}
        </span>
        <span>
          {{ 'COMMON_MESSAGES.NOT_AVAILABLE' | translate }}
        </span>
      </div>
    }
  </div>
</section>
@if (!isStandaloneMode) {
  <dpa-accordion-wizard-step-actions
    [isReadOnlyMode]="isReadOnlyMode"
    [isFinalStep]="isFinalStep"
    (actionNext)="onButtonClick()"
  ></dpa-accordion-wizard-step-actions>
}
