<div class="control-container">
  <div class="selection-type ph-x4 pv-x2">
    @if (isCustomTemplate) {
      <div class="selection-type-header custom-template">
        <div>{{ 'WORKFLOWS.AVAILABLE_DATA_SOURCES' | translate: { count: customGroupCollection.groups.length } }}</div>
        @if (showTemplates) {
          <div
            dpaTabEvent
            (click)="onCustomTemplateToggle(false)"
            (keyup.enter)="onCustomTemplateToggle(false)"
            class="clickable nav-control mr-x2 text-blue"
          >
            <div class="explore-templates-label">{{ 'WORKFLOWS.EXPLORE_TEMPLATES' | translate }}</div>
            <cds-icon
              shape="arrow"
              direction="right"
              size="22"
            ></cds-icon>
          </div>
        }
        @if (headerActionTemplate) {
          <ng-container [ngTemplateOutlet]="headerActionTemplate"></ng-container>
        }
      </div>
    } @else {
      <div class="selection-type-header predefined-template">
        <div
          dpaTabEvent
          (click)="onCustomTemplateToggle(true)"
          (keyup.enter)="onCustomTemplateToggle(true)"
          class="nav-control"
        >
          <cds-icon
            shape="arrow"
            direction="left"
            size="24"
            class="text-blue clickable"
          ></cds-icon>
          <div class="left-border pl-x2 ml-x1">{{ 'WORKFLOWS.AVAILABLE_TEMPLATES' | translate: { count: templates?.length } }}</div>
        </div>
      </div>
    }
    @if (description) {
      <div class="selection-type-description">
        {{ 'WORKFLOWS.SELECT_DATASOURCE_MSG' | translate }}
      </div>
    }
  </div>
  <div class="list-container">
    @if (isCustomTemplate) {
      <dpa-grouped-list
        [groupCollections]="[customGroupCollection]"
        [searchPlaceholder]="'TEMPLATE.SEARCH_DATA_SOURCES_ENTITIES' | translate"
        [groupsTitleByGroupBy]="labelByGroupBy"
        [groupIconTemplate]="groupIconTemplate"
        [isSinglePane]="false"
        [hideGroupPaneTitle]="true"
        [hasCompactItems]="true"
        [showListDivider]="true"
        [showSearch]="false"
        [showArrowWithCount]="false"
        [applyBoxStyling]="false"
        (itemSelect)="onSelect($event)"
      ></dpa-grouped-list>
    } @else {
      @if (areTemplatesLoaded) {
        <dpa-generic-list
          [isSearchable]="true"
          [isFilterable]="true"
          [items]="templates"
          [filterTags]="filterTags"
          [searchPlaceholder]="'TEMPLATE.SEARCH_TEMPLATES' | translate"
          [showListDivider]="false"
          (searchQueryChange)="onSearchQueryChange($event)"
          (filterChange)="onFilterChange($event)"
        >
          <ng-template
            let-item="item"
            let-last="last"
          >
            <div
              dpaTabEvent
              (click)="onPreview(item)"
              (keyup.enter)="onPreview(item)"
            >
              <ng-container
                [ngTemplateOutlet]="templateSummaryListItem"
                [ngTemplateOutletContext]="{ item: item }"
              ></ng-container>
            </div>
          </ng-template>
        </dpa-generic-list>
      } @else {
        <div class="spinner spinner-md templates-spinner"></div>
      }
    }
  </div>
</div>
<ng-template
  #groupIconTemplate
  let-group="group"
  let-groupCollection="groupCollection"
>
  @if (groupCollection?.groupBy === TemplateGroupBy.INTEGRATION) {
    <div
      [attr.data-service-type]="group?.name | uppercase"
      class="service-icon connection-icon"
    ></div>
  } @else {
    <cds-icon
      [attr.shape]="group?.iconShape ?? ''"
      size="24"
    ></cds-icon>
  }
</ng-template>
